<template>
    <div>
        <h3 class="subtitle">Team Members in <span class="text-break">{{team.name}}</span></h3>
        <div class="card">
            <div class="card-body">
                <div class="alert alert-blue" v-if="networkActivity">
                    {{networkActivity}}
                </div>
                <team-members-editor 
                    v-else
                    :team="team" 
                    :members="teamMembers" 
                    @showAssignmentEditor="showAssignmentEditor"
                /> 
            </div>
        </div>

        <br/>

        <div v-if="canManageTeam">
            <h3 class="subtitle d-flex">
                <span>
                    Open Invitations
                </span>
                <button class="btn btn-link ms-auto title-control" @click="showInvitationWidget">+ Invite Member</button>
                
            </h3>
            <div class="card">
                <div class="card-body" v-if="loadingInvitations || sendingInvitation">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                    </div>
                </div>
                
                <div class="card-body" v-if="showingInviteWidget && ! sendingInvitation">
                    <invitation-widget :team="team" :roles="availableAccountRoles" :folders="folders" @cancel="showingInviteWidget = false" @send="sendInvitation" />

                    <div class="alert alert-danger mt-3" v-if="invitationError">
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true"></span>
                        </button>
                        {{invitationError}}
                    </div>
                </div>
                <div class="card-body">
                    <div
                        class="alert alert-default small card-text"
                        v-if="invitations.length === 0 && !loadingInvitations"
                    >
                        No open invitations. Why don't you <a href="#" @click.prevent="showInvitationWidget">invite someone to {{team.name}}?</a> 
                    </div>

                    <table class="table table-striped table-condensed d-none d-md-table" v-if="invitations.length && ! showingInviteWidget">
                        <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Role</th>
                                    <th>Sent</th>
                                    <th>Expires</th>
                                    <th></th>
                                </tr>
                        </thead>
                        <tbody>
                            <invitation-table-row 
                                v-for="invite in invitations" 
                                :invitation="invite" 
                                :key="'invite-row-'+invite.id" 
                                @resend="resendInvitation"
                                @rescind="rescindInvitation"
                            />
                        </tbody>
                    </table>

                    <div class="list-group d-block d-md-none">
                               
                        <div class="list-group-item" v-for="invite in invitations" :key="'invite-row-'+invite.id">
                            <invitation-mini 
                                :invitation="invite" 
                                @resend="resendInvitation"
                                @rescind="rescindInvitation"
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>


    </div>

</template>

<script>

import { mapGetters } from 'vuex';

import TeamMembersEditor from './TeamMembersEditor';
import InvitationWidget from "./InvitationWidget"
import InvitationTableRow from "./OpenInvitations/Table"
import InvitationMini from "./OpenInvitations/Mini"

export default {
    props : ['team'],
    components : {
        TeamMembersEditor, InvitationWidget, InvitationTableRow, InvitationMini
    },
    computed : {
        ...mapGetters({
            users : 'teamMembers',
            accountTeamMembers : 'accountTeamMembers',
            'allFolders' : 'folders',
            currentUser : 'currentUser',
        }),
        folders(){
            return this.allFolders.filter(f => f.account === this.team.id);
        },
        teamMembers(){

            let team = this.accountTeamMembers.find(account => account.id === this.team.id);
            if (team){
                return this.users.map(user => {
                    return team.members.find(u => user.id === u.id)
                }).filter(u => u);
            }

            return [];


        },
        canManageTeam(){
            return this.$accessControl.can(this.team.role, ['createTeamMemberships', 'deleteTeamMemberships']);
        },
        availableAccountRoles(){
            var roles = this.$accessControl.rolesForRole(this.team.role);
            
            if (this.team.plan.includes('professional')){
                roles = roles.filter(r => r === 'reviewer')
            }

            return roles;
        }
    },
    data(){
        return {
            invitations : [],
            loadingInvitations : false,
            showingInviteWidget : false,
            memberInvitation : {
                email : '',
                role : '',
                folder_id : ''
            },
            sendingInvitation : false,
            invitationError : false,
            networkActivity : false,
        }
    },
    watch : {
        team(){
            console.log('* Manager.vue this.team', this.team)
            this.showingInviteWidget = false;
            this.invitations = [];
            this.resetMemberInvitation();
            this.fetchInvitations();
            this.invitationError = false;
        }
    },
    mounted(){
        this.fetchInvitations()
    },

    methods : {
        showAssignmentEditor({teamMember}){
            this.$emit('showAssignmentEditor', {teamMember});
        },
        showInvitationWidget(){
            this.showingInviteWidget = true;
        },
        fetchInvitations(){
            this.loadingInvitations = true;
            this.$api.get('/accounts/'+this.team.id+'/invitations')
            .then(response => {
                let urls = response.data;
                console.log('* Manager.vue fetchInvitations urls', urls)
                if(urls.length === 0) {
                    console.log('* Manager.vue no invites for this account')
                    this.loadingInvitations = false;
                    this.invitations = [];
                    return;
                }

                let remaining = urls.length;
            
                urls.forEach(url => {
                    this.$api.get(url).then(response => {
                        let invitation = response.data
                        let existing = this.invitations.find(i => i.id.includes(invitation.id));
                        let index = this.invitations.indexOf(existing);

                        if (index > -1){
                            this.invitations.splice(index, 1, invitation)
                        } else {
                            this.invitations.push(invitation);                            
                        }
                        remaining --;
                        if (remaining === 0){
                            this.loadingInvitations = false;
                        }
                    })
                });
            });
        },
        // privilegeTitle(mask){
        //     let role = this.availableAccountRoles.find(r => r.value == mask);
        //     if (role)
        //         return role.name;
        // },
        resetMemberInvitation(){
            this.memberInvitation = {email : '', role : '', folder_id : ''}
        },
        sendInvitation(invite){

            this.sendingInvitation = true;
            
            this.$api.post('/accounts/'+this.team.id+'/invitations', invite)
            .then((response) => {
                this.sendingInvitation = false;
                let invitation = response.data
                this.invitations.push(invitation)
                this.showingInviteWidget = false;
            }).catch(e => {
                this.sendingInvitation = false
                if (e.response){
                    this.invitationError = e.response.data.description;
                }
            })
            
        },
        resendInvitation(invitation){
            let x = confirm("Are you sure you want to resend this invitation?")
            if ( ! x) return;

            this.$api.delete(`/invitations/${invitation.id}`)           
            .then(() => {
                let existing = this.invitations.find(i => i.id.includes(invitation.id));
                let index = this.invitations.indexOf(existing);

                this.invitations.splice(index, 1);
                
                this.sendInvitation(invitation);
                this.resetMemberInvitation()
            })
        },
        rescindInvitation(invitation){
            let x = confirm("Are you sure you want to rescind this invitation?")
            if ( ! x ) return;

            let existing = this.invitations.find(i => i.id.includes(invitation.id));
            let index = this.invitations.indexOf(existing);

            this.invitations.splice(index, 1);

            return this.$api.delete(`/invitations/${invitation.id}`);
        }
    }
}
</script>

<style scoped>
.table-condensed td, .table-condensed th {
    font-size: 14px;
    padding: 4px 8px;
    border: none;
}

.table-condensed .btn-sm {
    padding: 0 4px;
}

.title-control {
    padding: 0;
    font-size: var(--font-size-13);
    margin-bottom: 0;
}

</style>
