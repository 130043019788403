<template>
    <modal ref="modal" @close="onClose" class="collections-modal" size="lg">
        <template v-slot:header>
            <h5>
            <span v-if="collection.id">
                    Edit {{ collection.name }}
                </span>
                <span v-else>
                    Create a New Collection
                </span>
            </h5>
        </template>
        <div class="form" :class="{'new-collection-ux': accountFeatures.newCollectionUx && !collection.id}">
            <tabs ref="tabs" :vertical="true">
                <tab label='Basics' iconClass='fa fa-bookmark'>
                    <p class="text-muted small">
                        Collections are the "staging areas" where you can send suggestions to be distributed to
                        different platforms.
                    </p>
                    <label>
                        Name
                        <input ref="nameInput" @keyup.enter="handleCollection" class="form-control mb-2"
                               v-model="collection.name" placeholder="Enter a Collection name"/>
                    </label>
                    <label v-if="showIconPicker">
                        Icon
                    </label>
                    <icon-picker :icons="icons" v-model="collection.icon" class="mb-3"></icon-picker>
                    <label>
                        Description
                        <textarea class="form-control mb-2" rows="3" v-model="collection.description"
                                  placeholder="Enter a Collection description (optional)"></textarea>
                    </label>
                    <label v-if="availableFolders.length > 0 && ! collection.id">
                        Folder
                        <select class="form-control form-select mb-3" v-model="collection.folder">
                            <option :value="rootFolderId">Select a Folder</option>
                            <option v-for="folder in availableFolders" :key="'query-builder-folder-'+folder.id"
                                    :value="folder.id">
                                {{ folder.name }}
                            </option>
                        </select>
                    </label>
                </tab>
                <tab label="Sources" iconClass="fa fa-th-list" :visible="showCollectionSources"
                     focus=".add-source--url">
                    <collection-sources :collection="collection" ref="collectionSources"></collection-sources>
                </tab>
                <tab label='Power-Ups' iconClass='fa fa-bolt' class="scroll-message-container">
                    <div>
                        <div>
                            <input type="search" placeholder="Search Powerups" v-model="powerupSearchQuery"
                                   class="form-control form-control-sm"/>
                        </div>
                        <div class="mb-1 ms-1">
                            <!--<label class="d-inline small me-1 align-self-end"><input type="radio" class="form-check-input" v-model="powerupFilter" value="all" name="powerupFilter"/> All</label>-->
                            <!--<label class="d-inline small me-1 align-self-end"><input type="radio" class="form-check-input" v-model="powerupFilter" value="authed" name="powerupFilter"/> Authenticated</label>-->
                            <!--<label class="d-inline small align-self-end"><input type="radio" class="form-check-input" v-model="powerupFilter" value="all" name="powerupFilter"/> Active</label>-->
                        </div>
                    </div>
                    <div class="accordion" style="overflow-y: scroll;">
                        <power-up-container
                            v-model="amplifySettings.on"
                            :service="Amplify"
                            :team="currentAccountOverview"
                            :beforeAuth="beforeAuth"
                            label="Hootsuite Amplify"
                            description="Empower your team and reach your audience."
                            :learn-more-url="learnUrls.amplify"
                            iconClass="amplify-icon icon-dynamic"
                            :visible="showAmplify && powerupSearchMatches('amplify hootsuite employee advocacy engagement social') && powerUpEnabled('amplify')"
                        >
                            <div v-if="accountHasAuthorizedAmplify && ! networkActivity">
                                <amplify-collection-settings v-model="amplifySettings" :all-topics="amplifyTopics"
                                                             :topics-loaded="amplifyTopicsLoaded"></amplify-collection-settings>
                            </div>
                        </power-up-container>
                        <power-up-container
                            :visible="powerupSearchMatches('sniply call to action cta conversion') && showSniply && powerUpEnabled('sniply')"
                            v-model="sniplyOn"
                            :service="Sniply"
                            :team="currentAccountOverview"
                            :beforeAuth="beforeAuth"
                            label="Sniply"
                            description="Drive conversions through content curation."
                            :learn-more-url="learnUrls.sniply"
                            iconClass="sniply-icon icon-dynamic"
                        >
                            <div v-if="sniplyOn && ! networkActivity">

                                <label>
                                    Sniply Campaign
                                </label>
                                <spinner v-if="!sniplyCampaignsLoaded" class="dropdown"></spinner>
                                <sniply-campaign-select :campaigns="sniplyCampaigns" v-model="sniplyCampaign"
                                                        class="mt-2">
                                </sniply-campaign-select>
                            </div>
                        </power-up-container>
                        <power-up-container
                            :visible="powerupSearchMatches('constant contact email') && accountFeatures.constant_contact && powerUpEnabled('constant_contact')"
                            :value="true"
                            :service="service('constant_contact')"
                            :team="currentAccountOverview"
                            :can-turn-on-off="false"
                            :beforeAuth="beforeAuth"
                            label="Constant Contact"
                            description="Engage your audience with email digests."
                            :learn-more-url="learnUrls.constantContact"
                            iconClass="constant_contact-icon icon-dynamic"
                        >
                            <constant-contact-collection-settings :collection="collection" :parent-modal="$refs.modal"
                                                                  :needs-reset="resetSignal">

                            </constant-contact-collection-settings>
                        </power-up-container>
                        <hubspot-email-powerup
                            :visible="powerupSearchMatches('hubspot email digest') && powerUpEnabled('hubspot')"
                            :collection="collection"
                            :team="currentAccountOverview"
                            :show-rss-form="showHubspotEmailRssForm"
                            @showRssFormChange="showHubspotEmailRssFormChanged"
                            :beforeAuth="beforeAuth">
                        </hubspot-email-powerup>
                        <power-up-container
                            :visible="powerupSearchMatches('hubspot email icebreaker') && accountFeatures.icebreaker && powerUpEnabled('icebreaker')"
                            v-model="icebreakerOn"
                            :service="service('icebreaker')"
                            :team="currentAccountOverview"
                            :beforeAuth="beforeAuth"
                            label="Icebreaker"
                            description="Engage your contacts directly within HubSpot."
                            :learn-more-url="learnUrls.icebreaker"
                            iconClass="icebreaker-icon icon-dynamic"
                        >
                        </power-up-container>
                        <mailchimp-email-powerup
                            :visible="powerupSearchMatches('mailchimp email digest') && powerUpEnabled('mailchimp')"
                            :collection="collection"
                            :team="currentAccountOverview"
                            :show-rss-form="showMailchimpEmailRssForm"
                            @showRssFormChange="showMailchimpEmailRssFormChanged"
                            :createParent="createAndKeepGoing"
                            ref="mailchimpPowerup"
                            :beforeAuth="beforeAuth">
                        </mailchimp-email-powerup>
                        <content-campaigns v-if="collection.id && accountFeatures.contentTemplates"
                                           ref="contentCampaigns" :collection-id="collection.id"
                                           :visible="powerupSearchMatches('html email digest')">

                        </content-campaigns>
                        <collection-quality-checks v-if="collection.id && features.proofpoint" ref="qualityChecks"
                                                   :collection-id="collection.id"
                                                   :visible="powerupSearchMatches('proof point proofpoint quality check')">

                        </collection-quality-checks>
                    </div>
                </tab>
                <tab label="RSS" iconClass="fa fa-rss" :visible="rssInCollectionsModal" class="rss-tab" v-if="false">
                    <p class="mb-1 text-muted small">Share your curation prowess with the world by creating custom
                        Power-Ups via RSS. Below are links to some of our favorites. A little stumped? Have one to add?
                        We want to hear from you!</p>
                    <ul class="no-bullets no-indent rss-links small">
                        <li><a href="https://mailchimp.com/help/share-your-blog-posts-with-mailchimp/">MailChimp</a>
                        </li>
                        <li><a
                            href="https://help.hootsuite.com/hc/en-us/articles/204585700-Add-pause-or-delete-an-RSS-feed">Hootsuite</a>
                        </li>
                        <li><a
                            href="https://knowledge.hubspot.com/articles/kcs_article/cos-blog/set-up-an-rss-to-email-blog-subscription-for-an-external-blog">HubSpot</a>
                        </li>
                        <li><a href="https://blog.bufferapp.com/introducing-rss-feeds-buffer-social-media-sharing">Buffer</a>
                        </li>
                        <li><a href="https://zapier.com/help/rss/">Zapier</a></li>
                    </ul>
                    <div v-if="collection.id">
                        <filters v-model="rssTabFilters"
                                 :default-filters="defaultRssTabFilters"
                                 :sources="publishers"
                                 :hasSuggestions="{type:'collection', id: collection.id}"></filters>
                        <label>
                            RSS Feed URL
                            <textarea :value="rssTabFilters.url" class="form-control rss-area"
                                      @focus="selectText($event.target)" readonly></textarea>
                        </label>
                    </div>
                    <div v-else class="text-muted mb-4">
                        Save your collection in order to access RSS feed URL
                    </div>
                </tab>
            </tabs>


            <div class="alert alert-danger mt-2" v-if="networkError">
                <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: no user entered data-->
                <span v-html="networkError"></span>
            </div>
        </div>
        <template v-if="networkActivity" v-slot:footer>
            <progress-bar style="height: 30px; width: 100%;"/>
        </template>
        <template v-slot:footer v-else>
            <ul class="errors" v-if="errorMessages && errorMessages.length">
                <li v-for="error in errorMessages" class="error" :key="error">{{ error }}</li>
            </ul>
            <div class="buttons">
                <button type="button" class="btn btn-secondary btn-sm me-auto" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary float-end" v-if="collection.id" @click="saveCollection">
                    Save
                    Collection
                </button>
                <button type="button" class="btn btn-primary float-end" v-else @click="createCollection"><i
                    class="fa fa-plus"></i> Create Collection
                </button>
            </div>
        </template>
    </modal>
</template>

<script>

import * as $ from 'jquery';
import moment from 'moment';
import {mapGetters} from 'vuex';
import ProgressBar from '@/utilities/ProgressBar'
import IconPicker from '@/components/Controls/IconPicker'
import {Tabs, Tab} from '@/components/Controls/Tabs'
import {iconOptions} from "@/services/Icons"
import PowerUpContainer from '@/components/Controls/PowerUpContainer'
import Modal from '@/components/Controls/Modal'
import {AmplifyConnection} from "@/services/Amplify";
import {SniplyConnection} from "../../services/Sniply";
import Filters from '@/components/FilteringAndSorting/FiltersForm.vue'
import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";
import SniplyCampaignSelect from '@/components/Integrations/Sniply/SniplyCampaignSelect'
// import * as rss from '@/services/RssLinks'
import Spinner from '@/components/Controls/Spinner'
import HubspotEmailPowerup from '@/components/Collections/powerups/HubspotEmailPowerup'
import MailchimpEmailPowerup from "@/components/Collections/powerups/MailchimpEmailPowerup";
import AmplifyCollectionSettings from "@/components/Integrations/Amplify/AmplifyCollectionSettings";
import ContentCampaigns from "@/components/Collections/powerups/ContentCampaigns";
import CollectionQualityChecks from "@/components/Collections/powerups/CollectionQualityChecks";
import {OAuthConnection} from "@/services/OAuth";
import ConstantContactCollectionSettings
    from "@/components/Integrations/ConstantContact/ConstantContactCollectionSettings";
import CollectionSources from "@/components/Collections/CollectionSources";


export default {
    // mixins: [RssFilterBase],
    components: {
        ConstantContactCollectionSettings,
        CollectionSources,
        CollectionQualityChecks,
        ContentCampaigns,
        AmplifyCollectionSettings,
        MailchimpEmailPowerup,
        HubspotEmailPowerup,
        ProgressBar,
        IconPicker,
        Tabs,
        Tab,
        PowerUpContainer,
        Modal,
        SelectFilterView,
        Filters,
        SniplyCampaignSelect,
        Spinner,
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            folders: 'folders',
            allCollections: 'collections',
            apis: 'externalApis',
            features: 'features',
            accountFeatures: 'accountFeatures',
            preferences: 'preferences',
            bannedIntegrationsForVendor: 'bannedIntegrationsForVendor',
            learnUrls: 'learnUrls'
        }),
        showCollectionSources() {
            return this.features.collectionSources;
        },
        Amplify() {
            return new AmplifyConnection(this)
        },
        Sniply() {
            return new SniplyConnection(this)
        },
        icons: () => iconOptions,
        rssInCollectionsModal() {
            return this.accountFeatures.rss;
        },
        showSniply() {
            return this.features.sniply;
        },
        showAmplify() {
            return this.features.amplify;
        },
        showIconPicker() {
            return this.features.collectionIcons;
        },
        accountHasAuthorizedAmplify() {
            return this.amplifyApiSettings.token;
        },
        amplifyApiSettings() {
            return this.apis.amplify[this.currentAccountOverview.id] ? this.apis.amplify[this.currentAccountOverview.id] : {};
        },
        availableFolders() {
            return this.folders.filter(
                f => f.account && f.account.includes(this.currentAccountOverview.id) && !this.currentAccountOverview.root.includes(f.id));
        },
        rootFolderId() {
            if (this.currentAccountOverview && this.currentAccountOverview.root) {
                let rootFolder = this.folders.filter(f => f).find(f => this.currentAccountOverview.root.includes(f.id));
                if (rootFolder) {
                    return rootFolder.id
                }
            }
            return null;
        },
        collections() {
            return this.allCollections.filter(c => c.account.includes(this.currentAccountOverview.id));
        },
        convertedAmplifySettings() {
            console.log('convertedAmplifySettings', this.amplifySettings)
            return {
                on: Boolean(this.amplifySettings.on),
                topics: this.amplifySettings.on ? this.amplifySettings.topics : [],
                isPublished: this.amplifySettings.isPublished,
                isEditable: this.amplifySettings.isEditable,
                summary: this.amplifySettings.summary && this.amplifySettings.summary !== 'no' ?
                    this.amplifySettings.summary : ''
            }
        },
        convertedSniplySettings() {
            return {
                sniply_campaign: this.sniplyCampaign
            }
        },
    },
    data() {
        return {
            collection: {
                folder: '',
                icon: 'bolt',
                name: '',
                description: ''
            },
            resetSignal: 0,
            powerupSearchQuery: '',
            powerupFilter: 'all',
            urlToAddToSources: '',
            sourceErrorMessage: '',
            sourcesBusy: false,
            sources: [],
            amplifySettings: {
                on: false,
                isPublished: false,
                isEditable: false,
                summary: '',
                publishType: false,
                topics: []
            },
            showHubspotEmailRssForm: false,
            showMailchimpEmailRssForm: false,
            errorMessages: [],
            allTags: [],
            amplifyTopics: [],
            sniplyCampaign: false,
            sniplyCampaigns: [],
            sniplyOn: false,
            constantContactOn: false,
            icebreakerOn: false,
            icebreakerWasOn: false,
            networkActivity: false,
            networkError: false,
            amplifyTopicsLoaded: false,
            sniplyCampaignsLoaded: false,
            publishers: [],
            defaultRssTabFilters: {
                age: -1,
                time: 'Forever',
                contentType: 'All',
                favorites: false,
                sortBy: 'Recency',
                evergreen: true
            },
            rssTabFilters: {
                filters: {
                    age: -1,
                    time: 'Forever',
                    contentType: 'All',
                    favorites: false,
                    sortBy: 'Recency',
                    evergreen: true
                }, url: ''
            }
        }
    },
    mounted() {
        var onShow;

        onShow = (collection) => {
            this.resetCollection();
            if (collection) this.collection = {...collection, icon: collection.icon || 'bolt'};
            this.fetchAmplifySettings();
            this.loadSniplyCampaignList().then(() => this.loadSniplySettings());
            this.loadIcbreakerSettings()
            this.loadAmplifyTopics();
            if (collection) this.loadFilters();
            this.show();
        }

        window.Events.$on('showCollectionCreationModal', onShow);


        this.$refs.modal.$el.addEventListener('hidden.bs.modal', () => {
            window.Events.$emit('collectionCreationModalDismissed');
            this.networkError = false;
            this.resetCollection();
        })

        this.collection.account = this.currentAccountOverview.id

        $(document).on('click', '.faux-router-link', () => {
            this.networkError = false;
            this.dismiss();
        });
    },
    methods: {
        fetchAmplifySettings() {
            if (!this.collection.id || !this.accountHasAuthorizedAmplify) {
                return;
            }
            this.networkActivity++
            this.$api.get('/collections/' + this.collection.id + '/integrations/amplify/settings')
                .then(response => {
                    console.log('response', response.data, response && response.data && response.data.settings)
                    if (response && response.data && response.data.settings) {
                        let settings = response.data.settings;
                        let topics = response.data.settings.topics;
                        console.log('pre-load amplify', response, settings)
                        this.amplifySettings = {
                            on: settings.on || false,
                            topics: topics,
                            isPublished: settings.isPublished || false,
                            isEditable: settings.isEditable || false,
                            summary: settings.summary,
                            publishType: settings.isPublished ? 'immediate' : settings.on ? 'draft' : false
                        }

                        console.log('load amplify', response, this.amplifySettings)
                    }
                    this.networkActivity--;
                })
                .catch(e => {
                    console.error(e)
                    this.networkActivity--;
                });
        },
        service(name) {
            return new OAuthConnection(name, this)
        },
        async loadAmplifyTopics() {
            if (!this.accountHasAuthorizedAmplify) return Promise.resolve();
            let response = await this.Amplify.fetchTopics(this.currentAccountOverview);
            this.amplifyTopics = response.data.data.map(x => x.name);
            this.amplifyTopicsLoaded = true;
        },
        async loadSniplySettings() {
            if (!this.apis['sniply'] || !this.collection || !this.collection.id) return Promise.resolve();
            this.networkActivity++;
            try {
                let response = await this.$api.get(`/collections/${this.collection.id}/sniply-settings`);
                if (response && response.data) {
                    let settings = response.data.settings;
                    this.sniplyOn = !!settings && !!settings.sniply_campaign_id;
                    this.sniplyCampaign = settings && settings.sniply_campaign_id;
                }
            } finally {
                this.networkActivity--;
            }
        },
        async loadIntegrationSettings(name) {
            if (!this.apis[name] || !this.collection || !this.collection.id) return Promise.resolve();
            this.networkActivity++;
            try {
                let response = await this.$api.get(`/collections/${this.collection.id}/integrations/${name}/settings`);
                if (response && response.data) {
                    return response.data.settings;
                }
            } finally {
                this.networkActivity--;
            }
        },
        async loadIcbreakerSettings() {
            this.loadIntegrationSettings('icebreaker').then(x => this.icebreakerOn = this.icebreakerWasOn = x && x.on)
        },
        async loadSniplyCampaignList(fetchNext) {
            if (!this.apis['sniply']) return Promise.resolve();
            if (fetchNext === undefined) this.sniplyCampaigns = [];

            let fetchFirst = (() => this.Sniply.fetchCampaigns(this.currentAccountOverview));
            let campaigns = await (fetchNext || fetchFirst)();
            this.sniplyCampaigns = this.$sort.alphabetize([...this.sniplyCampaigns, ...campaigns.results]);

            if (campaigns.hasNext && this.sniplyCampaigns.length < campaigns.count)
                await this.loadSniplyCampaignList(campaigns.next);
            else {
                this.sniplyCampaignsLoaded = true;
                this.fetchSniplyBrands();
            }
        },
        async loadFilters() {
            let id = this.collection.id;
            let [settings, allSources, tagData] = (await Promise.all([
                this.$api.get(`/collections/${id}/settings`, {}),
                this.$api.post(`/suggestion-data?collection=${id}`, {
                    age: -1,
                    count: true,
                    include: ["creator"],
                    type: "all",
                    undated: true,
                }),
                this.$api.get(`/collections/${id}/tags`)
            ])).map(response => response.data);
            if (!settings.filters) return;

            let includedSources = Object.keys(allSources).filter(x => !settings.filters.excludedSources.includes(x));
            let filters = {...settings.filters, sources: includedSources};
            let sourceCount = filters.sources && filters.sources.length || 0;
            let excludeCount = filters.excludedSources && filters.excludedSources.length || 0;
            filters.includeAllSources = excludeCount === 0;
            filters.includeNewSources = excludeCount <= sourceCount || filters.includeAllSources;

            this.publishers = Object.keys(allSources).map(source => ({source, count: allSources[source]}));
            this.allTags = tagData && tagData.tags || [];
            this.rssTabFilters = {filters, url: ''};
            this.defaultRssTabFilters = filters;
        },
        fetchSniplyBrands() {
            let brands = [...new Set(this.sniplyCampaigns.map(x => x.brand))];
            Promise
                .all(brands.map(brand =>
                    this.$api.get(`/accounts/${this.currentAccountOverview.id}/sniply/brands/${brand}/`)))
                .then(results => {
                    results.forEach(result => {
                        let brand = result.data;
                        this.sniplyCampaigns
                            .filter(campaign => campaign.brand === brand.id)
                            .forEach(campaign => {
                                campaign.brandName = brand.name;
                                campaign.brandPhotoUrl = brand.photo_url;
                            })
                    })
                });
        },
        handleCollection() {
            if (this.collection.id) {
                this.saveCollection()
            } else {
                this.createCollection();
            }
        },
        show() {
            this.collection.folder = this.collection.folder || this.rootFolderId;

            if (this.$refs.tabs)
                this.$refs.tabs.resetActiveTab();
            if (this.$refs.nameInput)
                this.$refs.nameInput.focus();

            // eslint-disable-next-line no-undef
            this.resetSignal++;
            this.$refs.modal.show();
        },
        onClose() {
            this.$refs.tabs.resetActiveTab();
        },
        dismiss() {
            this.$refs.modal.hide();
        },

        domain(url) {
            return url.replace(/https?:\/\//, '').replace(/\/.*/, '')
        },
        async showSourceLoader(promise) {
            this.sourcesBusy = true;
            try {
                await promise;
            } finally {
                this.sourcesBusy = false;
            }
        },
        showHubspotEmailRssFormChanged(val) {
            this.showHubspotEmailRssForm = val;
        },
        showMailchimpEmailRssFormChanged(val) {
            this.showMailchimpEmailRssForm = val;
        },
        formatFeedDate(date) {
            return date ? moment.utc(date).local().fromNow().replace(/^[a-z]/, s => s.toUpperCase()) : ''
        },
        async saveCollection() {
            this.validate();
            if (this.errorMessages.length) return;

            this.networkActivity = "Saving collection...";

            await this.$store.dispatch('addCollection', {collection: this.collection});
            await Promise.all([
                this.$api.put('/collections/' + this.collection.id + '/name', {name: this.collection.name}),
                this.$api.put('/collections/' + this.collection.id + '/icon', {icon: this.collection.icon}),
                this.$api.put('/collections/' + this.collection.id + '/description',
                    {description: this.collection.description}),
                this.$api.post(`/collections/${this.collection.id}/integrations/amplify/settings`,
                    this.convertedAmplifySettings),
                this.saveSniply(),
                this.saveIcebreaker(),
                this.saveQualityChecks()
            ]);

            this.resetCollection();
            this.networkActivity = false;
            this.dismiss();

        },
        async saveQualityChecks(collection) {
            if (this.$refs.qualityChecks && this.collection.id) {
                await this.$refs.qualityChecks.save(collection)
            }
        },
        async saveSniply() {
            if (!this.features.sniply) return Promise.resolve();
            if (this.sniplyOn && this.sniplyCampaign)
                return this.$api.post('/collections/' + this.collection.id + '/sniply-settings',
                    this.convertedSniplySettings);
            if (this.userRemovedSniply())
                return this.$api.delete('/collections/' + this.collection.id + '/sniply-settings').catch(() => {
                });
            else return Promise.resolve(); // User either
        },
        async saveIcebreaker() {
            if (!this.accountFeatures.icebreaker) return Promise.resolve();
            if (this.icebreakerOn)
                return this.$api.post('/collections/' + this.collection.id + '/integrations/icebreaker/settings',
                    {on: this.icebreakerOn});
            if (!this.icebreakerOn && this.icebreakerWasOn)
                return this.$api.delete('/collections/' + this.collection.id + '/integrations/icebreaker/settings')
                    .catch(() => {
                    });
            else return Promise.resolve(); // User either
        },
        userRemovedSniply() {
            return !this.sniplyOn;
        },

        validate() {
            this.errorMessages = [];
            if (!this.collection.name) this.errorMessages.push("Must set a collection name")
            // if (this.amplifySettings && this.amplifySettings.autoPost) {
            //     if (!this.amplifySettings.amplifyTopics || !this.amplifySettings.amplifyTopics.length)
            //         this.errorMessages.push("Must select a Amplify topic");
            // }
            if (this.sniplyOn) {
                if (!this.sniplyCampaign) this.errorMessages.push("Must select a Sniply campaign");
            }

        },
        async createAndKeepGoing() {
            this.collection = await this.createCollectionToServer();
        },
        async createCollectionToServer() {
            this.networkActivity = "Creating collection..."
            let description = this.collection.description ? this.collection.description : "";

            let collectionObject = {
                folder: this.collection.folder,
                name: this.collection.name,
                icon: this.collection.icon,
                description
            }

            try {
                let response = await this.$api.post('/accounts/' + this.currentAccountOverview.id + '/collections', collectionObject);
                this.networkActivity = false;
                let collection = {...response.data};

                await this.$store.dispatch('addCollection', {collection});

                await this.saveChildrenForNewCollection(collection);

                if (this.collections.length == 1) {
                    this.$store.dispatch('setCurrentCollection', {collection});
                }
                return collection;
            } catch (e) {
                if (e.response && e.response.data) {
                    this.networkActivity = false;
                    this.networkError = e.response.data.description
                    if (e.response.data.title.includes('exceeded')) {
                        this.networkError =
                            `You need to <a href="/settings#subscription" target="_blank"><b>upgrade your subscription plan</b></a> to add any more collections`;
                    }
                }
                return Promise.reject(e)
            }
        },
        async saveChildrenForNewCollection(collection) {
            this.$api.post('/collections/' + collection.id + '/integrations/amplify/settings',
                this.convertedAmplifySettings);
            if (this.features.sniply && this.sniplyCampaigns)
                this.$api.post(`/collections/${collection.id}/sniply-settings`, this.convertedSniplySettings);

            this.saveQualityChecks(collection)

            await this.showSourceLoader(this.$refs.collectionSources.saveSourcesForNewCollection(collection));
        },
        async createCollection() {
            this.validate();
            if (this.errorMessages.length) return;
            let collection = await this.createCollectionToServer();

            this.resetCollection();
            this.dismiss();
            return collection;
        },
        async beforeAuth() {
            this.networkError = false;
            let collection;
            if (this.collection.id) {
                collection = this.collection;
                await this.saveCollection();
            } else {
                collection = await this.createCollection();
            }

            this.$store.dispatch('setAfterOAuthData', {
                actions: [
                    ['showCollectionCreationModal', collection]
                ],
                url: window.location.pathname
            })
        },
        amplifyPubishChanged(...args) {
            console.log('amplifyPubishChanged', args)
            this.amplifySettings.isPublished = {...this.amplifySettings, isPublished: !this.amplifySettings.isPublished}
        },
        powerupSearchMatches(searchableTerms) {
            if (!this.powerupSearchQuery) return true;
            let searchTerms = this.powerupSearchQuery.split(' ');
            searchableTerms = searchableTerms.split(' ');
            return searchTerms.every(
                term => searchableTerms.some(word => word.toLowerCase().includes(term.toLowerCase())));
        },
        selectText(target) {
            target.setSelectionRange(0, target.value.length)
        },
        resetCollection() {
            console.log('reset collection dialog')
            this.resetSignal++;
            // if (this.$refs.contentCampaigns) this.$refs.contentCampaigns.reset();
            if (this.$refs.tabs) this.$refs.tabs.resetActiveTab();
            this.collection = {
                folder: this.rootFolderId,
                name: '',
                description: '',
                icon: 'bolt'
            }

            this.errorMessages = [];
            this.networkError = false;
            this.networkActivity = false;

            this.showHubspotEmailRssForm = false;
            this.showMailchimpEmailRssForm = false;

            if (this.$refs.addSourceForm && this.$refs.addSourceForm.resetForm)
                this.$refs.addSourceForm.resetForm(true);

            if (this.$refs.mailchimpPowerup)
                this.$refs.mailchimpPowerup.reset();

            this.sniplyCampaign = false;
            this.sniplyCampaigns = [];
            this.sniplyOn = false;
            this.icebreakerOn = false;
            this.icebreakerWasOn = false;
            this.amplifySettings = {
                topics: [],
                isPublished: false,
                isEditable: false,
                publishType: '',
                summary: 'title',
                on: false
            };
            this.sources = [];
            this.sourcesBusy = false;
        },
        powerUpEnabled(name) {
            return !this.bannedIntegrationsForVendor.includes(name)
        }
    }
}
</script>


<style lang="scss">
// Do not scope these styles or they may not work.
.new-collection-ux {
    .tab-bar {
        display: none!important;
    }
}
</style>

<style scoped lang="scss">
.advanced-toggle {
    font-size: var(--font-size-13);
    text-transform: uppercase;
}

.powerup-switch {
    margin-top: 0;
    margin-bottom: 0;
}

.advanced-options {
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    max-height: 0;
}

.advanced-options.show {
    max-height: 1500px;
}

textarea.rss-area {
    height: 95px;
    font-size: .9em;
}

.rss-tab summary {
    padding: .25em .5em;
    font-size: 13px;
    background-color: var(--bs-light);
    border: 1px solid var(--bs-default);
    color: var(--bs-dark);
}

.rss-links {
    columns: 4;
}

@media only screen and (max-width: 768px) {
    .rss-links {
        columns: 2;
    }
}

.auto-scroll {
    overflow: auto;
}

.height-500 {
    max-height: 300px;
}

.scroll-message-container {
    position: relative;
}

.scroll-message {
    position: absolute;
    bottom: 0;
    border-radius: 12px;
    background-color: var(--bs-primary);
    color: white;
    padding: 3px 10px;
    font-size: .8em;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 200px;
    text-align: center;
}

.scroll-message:hover {
    opacity: 0;
    filter: alpha(opacity=0);
}

.errors {
    padding-left: 0;
    color: var(--bs-danger);
    display: block;
}

.error {
    list-style: none;
    color: var(--bs-danger);
    font-size: 0.9em;
}

.modal-footer .buttons {
    display: flex;
    justify-content: right;
}

.collections-modal .tab-content {
    max-height: calc(100vh - 270px);
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (min-height: 600px) {
    .collections-modal .tab-content {
        min-height: calc(400px);
    }

}


.legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

/*.collections-modal .source-list{*/
/*max-height: calc(100vh - 270px);*/
/*overflow-y: auto;*/
/*overflow-x: hidden;*/
/*}*/
</style>


<style>
.collections-modal .modal-footer {
    display: block;
}

.collections-modal .modal-content {
    max-width: 700px;
    margin: auto;
    min-height: calc(100% - 100px);
    max-height: calc(100% - 100px);
}

.collections-modal {
    min-height: 100%
}
</style>
