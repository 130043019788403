<script setup>
import {computed, defineProps, ref, watch} from 'vue';
import Spinner from "@/components/Controls/Spinner.vue";
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import StackedBarChart from "@/components/Controls/charts/StackedBarChart.vue";
import {useTimeSeries} from "@/composables/useTimeSeriesReport";

console.log('report:click_history:setup:start');

const props = defineProps({
    filters: {type: Object, default: () => {}},
    by: {type: String, default: 'week'},
});

const isShowNoDataMessage = ref(false);

const {data, load, listen, isLoading} = useReportData(() => `by_collection_by_${props.by}`, store, () => props.filters);
const {getTimeSeriesData, labels} = useTimeSeries(props, data);

const collectionNames = computed(() => Array.from(new Set(data.value.map(x => x.collection_name))));

const collections = computed(() => collectionNames.value.map((name) => {
    let rows = data.value.filter(x => x.collection_name === name);
    let timeSeriesData = getTimeSeriesData(rows);
    return { name, data: timeSeriesData};
}));
const datasets = computed(() => collections.value.map((collection) => (
    {data: collection.data, label: collection.name, stack: 'a'}
)));

const xLabel = computed(() => {
    switch (props.by) {
        case 'day':
            return 'Day';
        case 'week':
            return 'Week beginning';
        case 'month':
            return 'Month';
        default:
            return 'Timespan';
    }
});

listen();
load().then(() => {
    console.log('report:click_history:data', collections.value)
    isShowNoDataMessage.value = data.value.length === 0;
});

console.log('report:click_history:setup:finish', datasets.value, labels.value);

watch(()=> data.value, () => {
    isShowNoDataMessage.value = data.value.length === 0;
});
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <StackedBarChart
        v-else-if="!isLoading && !isShowNoDataMessage"
        id="my-chart-id"
        style="max-width: inherit"
        :labels="labels"
        :x-label="xLabel"
        :y-label="'Number of Clicks'"
        :datasets="datasets"
    />
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
