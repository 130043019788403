<script setup>
import {StringUtils} from "@/utilities/Strings";

console.log('report:clicks_by_referrer:setup:start');
import {computed, defineProps, ref, watch} from 'vue';
import Spinner from "@/components/Controls/Spinner.vue";
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import BarChart from "@/components/Controls/charts/BarChart.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}}
});

const isShowNoDataMessage = ref(false);

const {data, load, listen, isLoading} = useReportData(() => `by_referrer`, store, () => props.filters);

listen();
load().then(() => {
    console.log('report:clicks_by_referrer:')
    isShowNoDataMessage.value = data.value.length === 0;
});
const labels = computed(() => data.value.map(x => [StringUtils.truncate(x.referrer_domain || 'No Referrer', 100)]));
const dataValues = computed(() => data.value.map(x => x.count));
const datasets = computed(() =>[ { data: dataValues.value } ]);

watch(()=> data.value, () => {
    isShowNoDataMessage.value = data.value.length === 0;
});
</script>


<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <BarChart
        v-else-if="!isLoading && !isShowNoDataMessage"
        id="my-chart-id"
        :labels="labels"
        :datasets="datasets"
    />
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
