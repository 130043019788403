<template>
    <div class="collection-list">
        <div class="collection-search-field" v-if="accountCollections.length > 10">
            <a v-if="searchText" class="clear-search-text" href="#" @click.prevent.stop="searchText = ''">
                <i class="fa fa-times-circle"></i>
            </a>
            <input
                    class="form-control input-sm mb-2"
                    v-model="searchText"
                    @focus="searchFocus"
                    @blur="searchBlur($event)"
                    placeholder="Search collections..."
                    @click.stop.prevent
            />
        </div>

        <by-folder :folder="folder"
                   :is-root="true"
                   :isCheckable="isCheckable"
                   v-model="selectedCollections"
                   :search="searchText"></by-folder>
    </div>
</template>

<style>
    .collection-search-field input {
        padding-right: 20px !important;
        font-size: 14px;
        height: 28px;
    }

    .collection-search-field {
        position: relative;
    }
    .collection-search-field a.clear-search-text {
        position: absolute;
        right: 4px;
        top: 2px;
    }
</style>

<script>
    import ByFolder from './ByFolder'
    import { mapGetters } from 'vuex';
    export default {
        props: ['folder', 'isCheckable', 'value'],
        components: {ByFolder},
        data() {
            return {
                searchText: '',
                selectedCollections: this.value,
            }
        },
        computed: {
            ...mapGetters({
                collections : 'collections',
                collectionsForCurrentAccount: 'collectionsForCurrentAccount',
            }),
            accountCollections(){
                return this.collectionsForCurrentAccount;
            }
        },
        watch: {
            selectedCollections() {
                this.$emit('input', this.selectedCollections)
            }
        },
        methods: {
            searchFocus(){
                window.Events.$emit('expandSidebar');
            },
            searchBlur(){
                window.Events.$emit('contractSidebar');
            }
        }
    }
</script>
