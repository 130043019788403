<template>
    <div>
    <label v-if="multipleShareProviders && !hideShare">
        Share Provider
    </label>
    <uc-select v-if="multipleShareProviders" :value="shareProvider" :items="shareProviderOptions" @input="shareProviderChange" ref="shareSelect" :disabled="disableShareProvider" v-show="!hideShare">
    </uc-select>
    <hr v-if="multipleShareProviders && !hideShare">
    <h5 class="mt-4">Third-Party Integrations</h5>
    <form @submit.prevent.stop class="mb-3">
        <div class="position-relative">
            <input type="search" class="form-control" v-model="searchText" placeholder="Search" />
            <button type="reset" @click="searchText=''" class="btn btn-sm text-muted fa fa-close position-absolute p-0 no-borders bg-transparent" style="right: 10px; top: 0; bottom: 0; margin: auto 0;"></button>
        </div>
        <div>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='social'">Social</button>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='email'">Email</button>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='image'">Images</button>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='cta'">CTA</button>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='employee advocacy'">Employee Advocacy</button>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='quality'">Content Quality</button>
            <button type="button" class="btn btn-link btn-sm ps-0 me-2" @click="searchText='other'">Other</button>
        </div>
    </form>
    <ul class="integration-list">
        <integration-item title="Buffer" icon="buffer" v-show="powerupSearchMatches('buffer social share')" v-if="powerUpEnabled('buffer')">
            <a class="btn btn-sm btn-primary float-end" href="https://buffer.com/extensions" target="_blank">Install Plugin</a>
            <div class="integration-features">
                <label class="text-muted small text me-2" :class="{active: shareProvider==='buffer'}" title="Features are enabled based on which features you have in the integrated app">
                    <a onclick="alert('Features are enabled based on which features you have in the integrated app')">
                        Available Features <span class="fa fa-info-circle text-muted"></span>:
                    </a>
                </label>
                <div class="integration-feature-tags">
                    <span class="tag integration-feature-tag small ms-1" >
                        <i class="fa fa-share me-1"></i>
                        Share
                        <span v-if="shareProvider === 'buffer'">(In Use)</span>
                        <span v-else>(Available, <button type="button" class="ui-link" @click="shareProviderChange('buffer')">Set as Default</button>)</span>
                    </span>
                </div>
            </div>
        </integration-item>

        <integration-meta-item :integration="integrationByName('everyone_social')" :team="team" v-show="powerupSearchMatches('everyone social employee advocacy')"  v-if="powerUpEnabled('everyone_social')">
        </integration-meta-item>

        <integration-meta-item :integration="integrationByName('constant_contact')" :team="team" v-show="powerupSearchMatches('constant contact crm email sales')"  v-if="powerUpEnabled('constant_contact')">
        </integration-meta-item>
        <integration-item title="Industry Weapon" icon="industry-weapon" v-show="powerupSearchMatches('industry weapon digital signage other')" v-if="powerUpEnabled('industryWeapon')">
            <a class="btn btn-sm btn-primary float-end" href="https://www.industryweapon.com/" target="_blank">Learn More</a>
        </integration-item>
        <integration-item title="Funnel Amplified" icon="funnelAmplified" v-show="powerupSearchMatches('funnel amplified social share employee advocacy website other')"  v-if="powerUpEnabled('funnelAmplified')">
            <a class="btn btn-sm btn-primary float-end" href="https://app.funnelamplified.com/connected_apps" target="_blank">Configure in Funnel Amplified</a>
            <div class="integration-features">
                <label class="text-muted small text me-2" :class="{active: shareProvider==='buffer'}" title="Features are enabled based on which features you have in the integrated app">
                    <a onclick="alert('Features are enabled based on which features you have in the integrated app')">
                        Available Features <span class="fa fa-info-circle text-muted"></span>:
                    </a>
                </label>
                <div class="integration-feature-tags">
                    <span class="tag integration-feature-tag small ms-1" >
                        <i class="fa fa-share me-1"></i>
                        Share
                        <span v-if="shareProvider === 'funnelAmplified'">(In Use)</span>
                        <span v-else>(Available, <button type="button" class="ui-link" @click="shareProviderChange('funnelAmplified')">Set as Default</button>)</span>
                    </span>
                </div>
            </div>
        </integration-item>
        <integration-item title="Hootsuite" icon="hootsuite" v-show="powerupSearchMatches('hootsuite social share')"  v-if="powerUpEnabled('hootsuite')">
            <a class="btn btn-sm btn-primary float-end" href="http://apps.hootsuite.com/185/upcontent" target="_blank">Install in Hootsuite</a>
        </integration-item>
        <integration-item title="Hootsuite Amplify" icon="amplify"  v-if="amplifyEnabled && powerUpEnabled('amplify')" v-show="powerupSearchMatches('hootsuite amplify employee advocacy other')">
            <connect-disconnect-button :service="Amplify" :team="team"></connect-disconnect-button>
            <div class="integration-features" v-if="isConnectedByName('amplify')">
                <label class="text-muted small text me-2" title="Features are enabled based on which features you have in the integrated app">
                    <a onclick="alert('Features are enabled based on which features you have in the integrated app')">
                        Available Features <span class="fa fa-info-circle text-muted"></span>:
                    </a>
                </label>
                <div class="integration-feature-tags">
                    <span class="tag integration-feature-tag small ms-1" >
                        <i class="fa fa-user me-1"></i>
                        <span class="me-2">Employee Advocacy</span>
                        <span>(Authorized, <button type="button" class="ui-link" @click="showNewCollection">Create Collection</button>)</span>
                    </span>
                </div>
            </div>
        </integration-item>
        <integration-meta-item :integration="integrationByName('hubspot')" :team="team" v-show="powerupSearchMatches('hubspot social share email website')"  v-if="powerUpEnabled('hubspot')">
        </integration-meta-item>
        <integration-meta-item :integration="integrationByName('icebreaker')" :team="team" v-show="powerupSearchMatches('hubspot icebreaker crm email sales')"  v-if="powerUpEnabled('icebreaker')">
        </integration-meta-item>
        <!--<integration-item title="MailChimp" img="https://static.mailchimp.com/web/brand-assets/mc_freddie_black_web.png">-->
            <!--<connect-disconnect-button :connected="false" @connect="function a(){}" @disconnect="function a(){}"></connect-disconnect-button>-->
            <!--&lt;!&ndash;https://mailchimp.com/help/share-your-blog-posts-with-mailchimp/&ndash;&gt;-->
        <!--</integration-item>-->
        <integration-meta-item :integration="integrationByName('mailchimp')" :team="team" v-show="powerupSearchMatches('mailchimp email')">
        </integration-meta-item>
        <integration-meta-item :integration="integrationByName('proofpoint')" :team="team" v-show="powerupSearchMatches('proof point compliance quality proofpoint')"  v-if="powerUpEnabled('proofpoint')">
        </integration-meta-item>
        <integration-meta-item :integration="integrationByName('getty')" :team="team" v-show="powerupSearchMatches('getty image images stock')">
        </integration-meta-item>
        <integration-item title="Sniply" icon="sniply" v-if="sniplyEnabled && powerUpEnabled('sniply')" v-show="powerupSearchMatches('sniply link shortener cta call to action')">
            <connect-disconnect-button :service="Sniply" :team="team"></connect-disconnect-button>
        </integration-item>

        <openai-integration-item ref="openai"></openai-integration-item>

    </ul>
    <h5 class="mt-5" v-if="!hideZapier">
        <i class="zapier-icon icon-dynamic me-1" style="vertical-align: middle"></i> <span  style="vertical-align: middle">Zapier Integrations</span>
    </h5>
    <zapier-integration-list></zapier-integration-list>
    </div>
</template>

<style lang="scss">
    .em1 {
        font-size: inherit !important;
    }
    .integration-list {
        list-style-type: none;
        padding-left: 0;
    }
    .integration-list .btn {
        margin-left: 10px;
    }


    .integration-feature-tag {
        color: #848d95;
        background: transparent none;
        border-color: #6c757d;
    }


    .integration-features {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
            min-width: 150px;
            width: 150px;
        }
        .integration-feature-tags {
            text-align: right;
        }
        grid-template-columns: 150px auto;
    }

    .integration-feature-tag.inactive {
        color: #c4d0d8;
        background: transparent none;
        border-color: #c4d0d8;
        ::selection {
            background-color: black;
        }
        .feature-title {
            text-decoration: line-through;
            ::selection {
                background-color: deepskyblue;
            }
        }

    }

    .hide {
        display: none;
    }

    @media screen and (max-width: 500px){
        .integration-features {

        }
        .integration-feature-tags {

        }

        .integration-list>li>.float-end {
            display: block;
            width: 100%;
            float: none;
        }
    }
</style>

<script>
    import { mapGetters } from 'vuex';
    import { AmplifyConnection } from '@/services/Amplify';
    import { SniplyConnection } from '@/services/Sniply';
    import IntegrationItem from './IntegrationItem'
    import ConnectDisconnectButton from './ConnectDisconnectButton'
    import UcSelect from '@/components/Controls/UpcontentSelect'
    import {getIconClass} from "@/services/Icons";
    import IntegrationLoader from '@/components/DataManagement/IntegrationLoader'
    import IntegrationMetaItem from "@/components/Settings/Team/IntegrationMetaItem";
    import ZapierIntegrationList from "@/components/Integrations/Zapier/ZapierIntegrationList";
    import OpenaiIntegrationItem from "@/components/Integrations/Openai/OpenaiIntegrationItem.vue";

    export default {
        name:'Integrations',
        props : ['team'],
        mixins: [IntegrationLoader],
        components: {ZapierIntegrationList, IntegrationMetaItem, IntegrationItem, ConnectDisconnectButton, UcSelect, OpenaiIntegrationItem},
        data() { return {
            account : {...this.team},
            amplify: {
                isConnected: false
            },
            searchText: ''
        }},
        watch : {
            team(){
                this.account = {...this.team}
                this.reloadIntegrationData();
            }
        },
        computed : {
            ...mapGetters({
                bannedIntegrationsForVendor: 'bannedIntegrationsForVendor',
                hideShare: 'hideShare',
                hideZapier: 'hideZapier'
            }),
            multipleShareProviders() { return this.shareProviders && !!this.shareProviderOptions.length; },
            amplifyEnabled() { return this.features.amplify; },
            sniplyEnabled() { return this.features.sniply; },
            Amplify() { return new AmplifyConnection(this) },
            Sniply() { return new SniplyConnection(this) },
            amplifyApiData() { return this.Amplify.apiData(this.team) },
            shareProviderOptions() {
                return this.$sort.alphabetize(this.shareProviders).map(sp => ({ value: sp.name, description: sp.title, iconClass: getIconClass(sp.name) }))
            },
            disableShareProvider() {
                return this.shareProviderOptions.length <= 1;
            },
        },
        methods: {
            removeAmplify() { return this.Amplify.disconnect(this.team) },
            setAccountToLoadAfterAuthorization() { return this.Amplify.connect(this.team) },
            integrationByName(name) {
                return this.metaIntegrations[name]
            },
            isConnectedByName(name) {
                let service = this.OAuth(name);
                return !!service && !!service.isConnected(this.account)
            },
            powerupSearchMatches(searchableTerms) {
                if (!this.searchText) return true;
                let searchTerms = this.searchText.split(' ');
                searchableTerms = searchableTerms.split(' ');
                return searchTerms.every(term => searchableTerms.some(word => word.toLowerCase().includes(term.toLowerCase())));
            },
            powerUpEnabled(name) {
                return !this.bannedIntegrationsForVendor.includes(name)
            },
        },
        mounted() {
            this.reloadIntegrationData();
            this.$refs.openai.loadAccountData();
        }
    }
</script>
