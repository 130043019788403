<template>
    <upcontent-select
        :items="options"
        :searchable="props.searchable"
        :multiple="props.multiple"
        :disabled="props.disabled"
        :filterable="props.filterable"
        :required="props.required"
        :placeholder="props.placeholder"
        :clearable="props.clearable"
        ref="ucSelect"
        :value="props.value"
        @input="onInput"
    >

    </upcontent-select>
</template>

<style>

</style>

<script setup>
import {computed, defineProps, ref, defineEmits} from 'vue'
    import UpcontentSelect from "@/components/Controls/UpcontentSelect";
    import {getIconClass} from "@/services/Icons";
    import 'vue-select/dist/vue-select.css';
    import {hasNonWhitepsaceValue, hasValue} from "@/utilities/utils";
    import store from '@/store'
    import {StringUtils} from "@/utilities/Strings";
    const $emit = defineEmits(['input'])
    const props = defineProps({
        type: String,
        value: String,
        multiple: Boolean,
        searchable: {type: Boolean, default: true},
        filterable: {type: Boolean, default: true},
        placeholder: {type: String, default: ''},
        clearable: {type: Boolean, default: false},
        disabled: Boolean,
        exclude: String,
        required: Boolean,
        additionalOptions: {type: Array, default: () => []},
        crossAccount: {type: Boolean, default: true}
    })

    const ucSelect = ref(null);
    const crossAccountSources = computed(() => {
        return props.crossAccount
            && store.getters.features.crossAccount;
    })
    const collections = computed(() => {
        return store.getters.collections
            .map(addInfo)
            .filter(x => x.isCurrentAccount || (crossAccountSources.value && x.isCrossAccount))
            .sort(sortCompare)
    })
    const topics = computed(() => {
        return store.getters.topics.filter(x =>
            x.account.includes(store.getters.currentAccountOverview.id)
            || store.getters.accountsWithCrossAccount.includes(x.account_id))
    })
    const items = computed(() => {
        return (
            props.type === 'collection' ? collections.value :
            props.type === 'topic' ? topics.value :
            [...collections.value, ...topics.value]
        )
    })
    const placeholder = computed(() => {
        let types = props.type ? [props.type] : ['topic', 'collection'];
        types = types.map(x => x.replace('special:collection:', ''))
        let pluralizeIfMultiple = t => props.multiple ? t+'s' : t;
        let thingsToSelect = types
            .map(pluralizeIfMultiple)
            .map(StringUtils.capitalize)
            .join('or')
        thingsToSelect = props.multiple ? thingsToSelect : 'a ' + thingsToSelect
        return `Select ${thingsToSelect}`

    })
    const options = computed(() => {
        const results = items.value
            .filter(x => x.id !== props.exclude)
            .map(x => {
                let folders = folderNames(x).filter(hasNonWhitepsaceValue).map(x => ({
                    icon: 'fa fa-folder-o',
                    name: x
                }));
                let accountName = x.account ? formatAccountName(x) : '';
                let account = accountName ? {
                    name: accountName,
                    icon: 'fa fa-users'
                } : null;
                let path = [account, ...folders].filter(hasValue)
                return {
                    value: x.id,
                    description: x.name,
                    iconClass:
                        x.icon !== undefined ? getIconClass(x.icon || 'bolt') : 'fa fa-th-list',
                    path

                }
            })
        return props.additionalOptions && props.additionalOptions.length ? [...props.additionalOptions, ...results] : results;
    })
    function onInput(v) {
        $emit('input', v)
    }
    function loadAccountCollections(accountId) {
        const $api = window.$app.api
        $api.get(`/accounts/${accountId}/overview`).then(response => {
            let overview = response.data;
            store.dispatch('addTopics', {topics : overview.topics.map(o => {
                    return {...o, account : accountId, incomplete: true} //FIXME do we need entire overview here?
                })})

            store.dispatch('addCollections', {collections : overview.collections.map(o => {
                    return {...o, account : accountId, incomplete: true}
                })})
            store.dispatch('addFolders', {folder: overview.store.getters.folders.map(o => {
                    return {...o, account : accountId, incomplete: true}
                })})
        })
    }
    function sortCompare(a, b) {
        let aFirst = -1;

        if (a.isCurrentAccount && b.isCurrentAccount)
            return a.name < b.name ? aFirst : -aFirst;
        else if (a.isCurrentAccount)
            return aFirst;
        else if (b.isCurrentAccount)
            return -aFirst;
        else
        if (a.accountInfo.name !== b.accountInfo.name)
            return a.accountInfo.name < b.accountInfo.name ? aFirst : -aFirst;
        else
            return a.name < b.name ? aFirst : -aFirst;
    }
    function folder(collection) {
        return store.getters.folders.find(f => collection.folder && collection.folder.includes(f.id))
    }
    function parents(folder) {
        return folder.parents && folder.parents.map(p => store.getters.folders.find(f => p.includes(f.id))).filter(hasValue) || []
    }
    function ancestors(folder) {
        let p = parents(folder);
        return [...p.flatMap(p => ancestors(p)), ...p]
    }
    function folderNames(collection) {
        let f = folder(collection);
        if (!f) return [];
        let parents = ancestors(f);
        let folders = [...parents, f];
        return folders.map(folder => folder && folder.name).filter(x => x !== 'root');
    }
    function formatAccountName(c) {
        return c.isCrossAccount ? c.accountInfo.name : ''
    }
function addInfo(collection) {
    let isCurrentAccount = collection.account.includes(store.getters.currentAccountOverview.id);
    let isCrossAccount = !isCurrentAccount && store.getters.accountsWithCrossAccount.some(a => collection.account.includes(a));
    let accountInfo = store.getters.allAccounts.find(a => collection.account.includes(a.id));
    return {
        ...collection,
        accountInfo,
        isCurrentAccount,
        isCrossAccount,
    }
}
</script>
