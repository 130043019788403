<template>
    <div>
        <div class="d-flex justify-content-center">
            <div class="download-message mt-3" v-if="networkActivity">
                {{networkActivity}}
            </div>

            <div class="download-message mt-3" v-if="networkError">
                <b class="text-danger">{{networkError}}</b>
                <br/>
                <router-link to="/settings">Back to Settings</router-link>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
    import {SniplyConnection} from "@/services/Sniply";
    import {mapGetters} from 'vuex'
    import {OAuthConnection} from "@/services/OAuth";
    import HasGlobalQualityCheckList from '@/components/QualityChecks/HasGlobalQualityCheckList'

    export default {
        mixins: [HasGlobalQualityCheckList],
        props: {
            apiName: String
        },
        computed : {
            ...mapGetters({
                currentAccountOverview: 'currentAccountOverview',
                accounts: 'accountToLoadAfterOauth',
                oauthActivity : 'oauthActivity',
                afterOauthData: 'afterOauthData'
            }),
            apiAccount() {
                return this.accounts[this.apiName] || this.currentAccountOverview
            },
            networkActivity() {
                return this.oauthActivity[this.apiName].networkActivity
            },
            networkError() {
                return this.oauthActivity[this.apiName].networkError
            }
        },
        mounted() {
            let afterActions = [];
            console.log('OAuth.vue.mounted', {api: this.apiName})
            if (this.apiName === 'proofpoint') {
                afterActions = [() => this.fetchChecksAndInitializeDoNotExist()]
            }
            if (this.apiAccount) {
                this.service().postAuthData(this.apiAccount, this.afterOauthData, afterActions);
            }
        },
        methods: {
            service() {
                switch (this.apiName) {
                    case 'sniply': return new SniplyConnection(this);
                    default: return new OAuthConnection(this.apiName, this)
                }
            }
        }
    }
</script>
