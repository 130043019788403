<script setup lang="ts">
import {computed, defineProps, watch} from 'vue';
import {Bar} from "vue-chartjs";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {chartColors} from "@/Constants";
console.log('report:bar:setup')
const props = defineProps({
    datasets: {type: Array<any>},
    labels: {type: Array<any>},
    displayAxisLabels: {type: Boolean, default: true},
    xLabel: {type: String || null},
    yLabel: {type: String || null},
    id: {type: String},
})
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const colors = Object.values(chartColors);
const chartData = computed(() => ({
    labels: props.labels,
    datasets: props.datasets.map((dataset: any, index) => (
        { ...dataset, backgroundColor: colors[index], stack: 'a' }
    ))
}));

const chartOptions = {
    responsive: true,
    aspectRatio: 3,
    scales: {
        x: {
            stacked: true,
            title: {
                display: true,
                text: props.xLabel
            }
        },
        y: {
            stacked: true,
            title: {
                display: true,
                text: props.yLabel
            }
        }
    },
}
watch(chartData, x => console.log('report:bar:data', x))
</script>

<template>
    <Bar
        :id="id"
        :options="chartOptions"
        :data="chartData"
    />
</template>

<style scoped>

</style>
