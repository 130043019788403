<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {defineProps, computed, ref, watch} from 'vue'
import PieChart from "@/components/Controls/charts/PieChart.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const labels = computed(() => data.value.map(x => x.country_name));
const datasets = computed(() => [{data: data.value.map(x => x.count)}]);

const {data, load, listen, isLoading} = useReportData('by_country', store, () => props.filters);
listen();
load().then(() => {
    console.log('report:by_country:data', data.value.map(x => x.count || 0))
    isShowNoDataMessage.value = data.value.length === 0;
});

watch(()=> data.value, () => {
    isShowNoDataMessage.value = data.value.length === 0;
});
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <PieChart
        v-else-if="!isLoading && !isShowNoDataMessage"
        id="my-chart-id"
        :labels="labels"
        :datasets="datasets"
    />
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
