import {StringUtils} from '@/utilities/Strings'

export let faIcons = {
    website: 'globe',
    mail: 'envelope',
    carousel: 'image',
    cards: 'th',
    bolt: 'bolt',
    bookmark: 'bookmark',
    rss: 'rss',
    discussion: 'comments',
    team: 'users',
    news: 'newspaper-o',
    filter: 'filter',
    check: 'check-circle',
    social: 'share-alt'

};
export let bgIcons = {
    amplify: 'amplify',
    everyone_social: 'everyone_social',
    buffer: 'buffer',
    funnelAmplified: 'funnelAmplified',
    hootsuite: 'hootsuite',
    'industry-weapon': 'industry-weapon',
    hubspot: 'hubspot',
    mailchimp: 'mailchimp',
    proofpoint: 'proofpoint',
    sniply: 'sniply',
    shutterstock: 'shutterstock',
    zapier: 'zapier',
    icebreaker: 'icebreaker',
    getty: 'getty',
    constant_contact: 'constant_contact',
    zest: 'zest',
    openai: 'openai',
};

export let allIcons = [...Object.keys(faIcons), ...Object.keys(bgIcons)].filter(x => x !== 'shutterstock');

export function getIconClass(icon) {
    let isFa = !!faIcons[icon];
    let isBg = !!bgIcons[icon];
    return isFa ? `fa fa-${faIcons[icon]}` : isBg ? `icon-dynamic ${bgIcons[icon]}-icon` : `fa fa-${icon}`;
}

export function getIconDescription(i) {
    if (i === 'hubspot') return 'HubSpot';
    if (i === 'rss') return 'RSS';
    return StringUtils.capitalizeWords(i.replace('-', ' ').replace('_', ' '))
}

export let iconOptions = allIcons.map(i => ({
    value: i,
    iconClass: getIconClass(i),
    label: getIconDescription(i)
}));


export function getEntityIcon(type, id, store) {
    if (!type) return ''

    type = type.toLowerCase()
    if (type.startsWith('gallery')) return 'fa fa-globe'
    if (type === 'topic') return 'fa fa-th-list'
    if (type === 'collection') {
        let collection = store.getters.collections.find(c => c.id === id)
        let hasIcon = collection && collection.icon
        return hasIcon ? getIconClass(collection.icon) : 'fa fa-bolt'
    }
    return ''
}
