<script setup>
import {computed, defineProps, ref, watch} from 'vue';
import store from '../../store';
import Spinner from "@/components/Controls/Spinner.vue";
import {useReportData} from "@/composables/useReportData";
import DashboardTable from "@/components/Controls/charts/DashboardTable.vue";
import {StringUtils} from "@/utilities/Strings";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const {data, load, listen, isLoading, nextPage} = useReportData('by_user_agent', store, () => props.filters, {limit: 250});

listen();
load().then(() => {
    console.log('report:user_agents:data', data.value.map(x => x.count || 0))
    isShowNoDataMessage.value = data.value.length === 0;
});

const rows = computed(() => data.value.map(x => [StringUtils.truncate(x.user_agent_string, 200), x.count]));

watch(()=> data.value, () => {
    isShowNoDataMessage.value = data.value.length === 0;
});
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <dashboard-table
        v-else-if="!isLoading && !isShowNoDataMessage"
        :rows="rows"
        @next="nextPage"
        :labels="['User Agent', 'Clicks']"
    ></dashboard-table>
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
