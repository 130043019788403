<template>
    <div>
        <div class="form-header">
            <h1 style="height: 3rem">
                <input v-show="editingName"
                       v-model="name"
                       @blur="saveName"
                       ref="name"
                       class="mt-3 form-control form-control-sm width-unset"/>
                <span v-show="!editingName" @click="edit()" class="clickable mt-4">{{
                        name
                    }} <span class="fa fa-edit small"></span> </span>
                <span class="form-footer-buttons float-end mb-4">
                    <button class="btn btn-secondary btn-sm me-2" type="button" @click="cancel">Back</button>
                    <button class="btn btn-secondary btn-sm me-2" type="button" @click="saveDraft">Save Draft</button>
                    <button class="btn btn-primary btn-sm"
                            type="button"
                            v-if="accountFeatures.gallery_builder"
                            @click="publish">Publish</button>
                </span>
            </h1>
        </div>
        <div class="alert alert-green d-flex justify-content-between" v-if="!accountFeatures.gallery_builder">
            <div>
                The plan for this account does not include Galleries. You will not be able to publish to your website
                until you
                <router-link to="/settings#subscription">Upgrade</router-link>
                .
            </div>
            <router-link class="btn btn-primary btn-sm" to="/settings#subscription">Upgrade</router-link>
        </div>
        <div class="row">
            <div class="column col-12 col-md-3 col-lg-3">
                <tabs ref="tabs">
                    <tab :label="`Layout/${$strings.capitalize(type)}`" style="font-size: .9em">
                        <details>
                            <summary>Base Text Styles</summary>
                            <label>
                                Font Size
                                <input v-model="fontSize"
                                       type="range"
                                       min="4"
                                       max="20"
                                       step="1"
                                       class="form-control form-control-sm"/>
                                {{ fontSize }} px
                            </label>
                            <label>
                                Text
                                <color-picker v-model="textColor"/>
                            </label>
                            <label>
                                Default Fallback Font
                                <select v-model="presetFont" class="form-control form-control-sm form-select">
                                    <option>Helvetica, Arial, sans-serif</option>
                                    <option>Tacoma</option>
                                    <option>Georgia, serif</option>
                                    <option>Times, serif</option>
                                    <option>Courier New, Courier</option>
                                    <option>Verdana</option>
                                    <option>Impact</option>
                                    <option>"Palatino Linotype", "Book Antiqua", Palatino, serif</option>
                                    <option>"Arial Black", Gadget, sans-serif</option>
                                </select>
                            </label>
                            <label>
                                Default Custom Font
                                <input type="text" class="form-control form-control-sm"/>
                            </label>
                        </details>
                        <details>
                            <summary>Layout</summary>
                            <label class="">
                                Layout Type
                                <select v-model="layout" class="form-control form-control-sm form-select">
                                    <option value="flex-start">Start/Left</option>
                                    <option value="flex-end">End/Right</option>
                                    <option value="center">Center</option>
                                    <option value="space-between">Space Between - (Not IE Compatible)</option>
                                    <option value="space-around">Space Around - (Not IE Compatible)</option>
                                    <option value="space-evenly">Space Evenly - (Not IE Compatible)</option>
                                    <option value="grow">Grow</option>
                                </select>
                            </label>
                            <fieldset class="mt-1 mb-3">
                                <legend>Margin</legend>
                                <label class="d-inline-block">Vertical <input v-model="verticalMargin"
                                                                              class="form-control form-control-sm d-inline-block"
                                                                              style="width: calc(50% - 2em)"
                                                                              type="number" ></label>
                                <label class="d-inline-block">Horizontal <input v-model="horizontalMargin"
                                                                                class="form-control form-control-sm d-inline-block"
                                                                                style="width: calc(50% - 2em)"
                                                                                type="number"></label>
                            </fieldset>
                        </details>
                        <details>
                            <summary>{{ $strings.capitalize(type) }}</summary>
                            <container-styles v-model="cardProps" :type="type"></container-styles>
                        </details>

                        <!--<label v-if="isCarousel">-->
                        <!--Accent-->
                        <!--<color-picker v-model="brandColor"/>-->
                        <!--</label>-->
                        <details>
                            <summary>{{ $strings.capitalize(type) }} CSS Properties</summary>
                            <prop-editor label="" v-model="cardDynamicProps"></prop-editor>
                        </details>
                    </tab>
                    <tab label="Element">
                        <label class="">
                            Edit Element
                            <select class="form-control form-control-sm form-select" v-model="currentElementClass">
                                <!--<option>Quick/Global</option>-->
                                <option v-for="el in defaultElements" :value="el.className" :key="el.className">{{
                                        el.name
                                    }}
                                </option>
                                <!--<li>Card</li>-->
                                <!--<li>Title</li>-->
                                <!--<li>Summary</li>-->
                                <!--<li>Date</li>-->
                                <!--<li>Source</li>-->
                            </select>
                        </label>
                        <design-element-props v-if="currentElement && currentElement.styles"
                                              :value="currentElement"
                                              @input="updateCurrentElement"></design-element-props>
                        <prop-editor v-if="currentElement && currentElement.userStyles !== undefined"
                                     v-model="currentElement.userStyles">

                        </prop-editor>
                    </tab>
                </tabs>
            </div>


            <div class="column col-12 col-md-9 col-lg-9">

                <div class="row small">
                    <label class="col col-12 col-md-3 col-lg-3">
                        Preview Type
                        <select v-model="previewSelectedValue" class="form-control form-control-sm form-select">
                            <option v-for="i in previewOptions" :key="i.value" :value="i.value">{{
                                    i.description
                                }}
                            </option>
                            <!--<option :value="0">Some Gallery</option>-->
                        </select>
                    </label>
                    <div class="col col-12 col-md-3 col-lg-3">
                        <label>
                            Preview Background
                            <color-picker v-model="previewBackground"/>
                        </label>
                        <!--<label>-->
                        <!--<input class="" type="checkbox">-->
                        <!--Full Width on Mobile-->
                        <!--</label>-->
                    </div>
                    <label class="col col-12 col-md-6 col-lg-6">
                        Preview Size (drag slider below to resize)
                        <output class="d-block text-muted">{{ frameSizePixels }} px</output>
                        <!--<label>-->
                        <!--<input class="" type="checkbox">-->
                        <!--Full Width on Mobile-->
                        <!--</label>-->
                    </label>

                </div>
                <input class="form-control" type="range" v-model="frameSize" min="0" max="100" step="1"/>
                <div :style="`max-width: ${frameSize}%`" ref="previewArea">
                    <div v-if="!livePreview" class='frame' :style="{background: previewBackground||'none'}">
                        <div class="card-container" :style="cardContainerStyle">
                            <div class="card" v-for="card in cards" :key="card" :style="cardStyle">
                                <card-preview v-if="type ==='card'"
                                              :index="card"
                                              :elementStyles="computedElementStyles"
                                              @selectElement="selectElement"></card-preview>
                                <carousel-preview v-else-if="type==='carousel'"
                                                  :index="card"
                                                  :elementStyles="computedElementStyles"
                                                  @selectElement="selectElement"></carousel-preview>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card-preview" style="min-height: 500px;">
                        <iframe class="card-preview-frame"
                                height="500px"
                                :src="previewUrl"
                                style="width: 100%; display: block; min-height: 100%;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

legend {
    border-bottom: 1px solid var(--bs-default);
    font-size: 110%
}

fieldset {
    margin-bottom: 2em;
}

.card-preview-frame {
    margin: -15px;
}

.card-preview {
    /*border: 1px solid black;*/
    padding: 5px;
    overflow: hidden;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.card {
    border: 1px solid black;
}

.frame {
    max-width: 100%;
    border: 1px dashed var(--bs-default);
    margin: 10px;
}

.clickable {
    cursor: pointer;
}

.fa.fa-edit.small {
    font-size: 70%;
}

.width-unset {
    width: unset;
    display: inline-block;
}

.form-header h1 {
    border-bottom: 1px solid #463179;
    color: #463179;
    padding-bottom: 5px;
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 1.5em;
}

.form-header .form-footer-buttons .btn {
    font-size: 1rem;
}
</style>

<script>
import DesignElementProps from './DesignElementProps'
import CardPreview from './CardPreview'
import * as Controls from '@/components/Controls'
import {iframeUrl} from "@/services/Gallery";
import TextStyle from './TextStyle'
import ContainerStyles from './ContainerStyles'
import PropEditor from './PropEditor'
import {
    cardContainerStyle,
    cardStyle,
    elementStyle,
    generateJsStyles,
    propsToLower
} from "@/services/GalleryJsStyles";
import {styleSheet} from "@/services/JsStylesToCss";
import Spinner from "@/components/Controls/Spinner";
import CarouselPreview from "@/components/Galleries/CarouselPreview";
import {newTheme, upgradeTheme} from "@/models/themes";
import {mapGetters} from 'vuex';
import * as toastr from "toastr";
import Padding from "@/components/Galleries/Padding";
import {isIterable} from "@/utilities/utils";

export default {
    components: {
        Padding,
        CarouselPreview,
        Spinner, DesignElementProps, CardPreview, ...Controls, TextStyle, ContainerStyles, PropEditor
    },
    props: {
        type: String,
        id: String
    },
    data() {
        const theme = newTheme(this.type);
        return {
            savedData: '',
            lastAutoSave: null,
            theme,
            loading: false,
            elementStyles: [],
            editingName: false,
            name: 'Default',
            frameSize: 100,
            previewSelectedValue: 5,
            previewBackground: null,
            availableGalleries: [],
            siteId: null,
            currentElementClass: '',

            ...theme.base,
            ...theme.layout,
            userStyles: theme.cardProps.userStyles,
            cardDynamicProps: theme.cardProps.userStyles || [],
            cardProps: theme.cardProps,
            elements: theme.elements || [],
            frameSizePixels: null,
            resizeHandler: null,
        }
    },
    watch: {
        defaultElements() {
            this.elements = this.defaultElements
        },
        frameSize() {
            this.calcFrameSize()
        }
    },
    computed: {
        ...mapGetters({
            accountFeatures: 'accountFeatures',
        }),
        numCards() {
            return typeof this.previewSelectedValue === 'number' ? this.previewSelectedValue : 5
        },
        previewGallery() {
            return typeof this.previewSelectedValue === 'string' ? this.previewSelectedValue : null
        },
        isCard() {
            return this.type === 'card'
        },
        isCarousel() {
            return this.type !== 'card'
        },
        defaultElements() {
            return ((this.theme.elements) || newTheme(this.type).elements || []).filter(x => x.selectable !== false);
        },
        rootElement() {
            return this.theme.rootElement;
        },
        layoutProps() {
            return {layout: this.layout, horizontalMargin: this.horizontalMargin, verticalMargin: this.verticalMargin}
        },
        baseProps() {
            return {
                fontSize: this.fontSize,
                backgroundColor: this.backgroundColor,
                brandColor: this.brandColor,
                color: this.textColor,
                presetFont: this.presetFont,
                customFont: this.customFont,
                userStyles: {},
            }
        },
        previewOptions() {
            let cardNumbers = this.isCard ? [5, 7, 11] : [1, 3, 5];
            let cardNumberOptions = cardNumbers.map(x => ({value: x, description: `${x} Card Editable`}));
            let galleryOptions = this.availableGalleries.map(x => ({value: x.id, description: x.name}));
            return [...cardNumberOptions, ...galleryOptions];
        },
        allStyleProps() {
            return {
                cardProps: {...this.cardProps, userStyles: this.userPropsObj(this.cardDynamicProps)},
                layout: this.layoutProps,
                elements: this.elements,
                base: this.baseProps
            }
        },
        computedElementStyles() {
            return this.elements.map(e => ({
                className: e.className,
                styles: {
                    ...elementStyle({
                        element: e.styles || {},
                        base: this.baseProps
                    }), ...propsToLower(e.userStyles)
                }
            })).concat([
                {className: 'content', styles: this.cardContentStyles}
            ]);
        },
        cardContentStyles() {
            return {
                // ...(this.cardProps.alignContentBottom? {
                //     position: absolute
                // } : {}),
                paddingTop: `${this.cardProps.contentTopPadding}px`,
                paddingBottom: `${this.cardProps.contentBottomPadding}px`,
                paddingLeft: `${this.cardProps.contentHorizontalPadding}px`,
                paddingRight: `${this.cardProps.contentHorizontalPadding}px`,
            }
        },
        cards() {
            return [...new Array(this.numCards).keys()]
        },
        livePreview() {
            return typeof this.previewSelectedValue === 'string';
        },
        cardContainerStyle() {
            return cardContainerStyle(this.allStyleProps)
            // return {
            //     justifyContent: this.layout === 'grow' ? 'flex-start' : this.layout,
            //     margin: `-${this.verticalMargin}px -${this.horizontalMargin}px`,
            // }
        },
        cardStyle() {
            return {...cardStyle(this.allStyleProps), ...this.userPropsObj(this.cardDynamicProps) }
            // return {
            //     fontSize: `${this.fontSize}px`,
            //     width: `${this.cardProps.size}px`,
            //     margin: `${this.verticalMargin}px ${this.horizontalMargin}px`,
            //     flexGrow: this.layout === 'grow' ? 1 : 0,
            //     backgroundColor: this.cardProps.cornerRoundRadius,
            //     borderRadius: `${this.cardProps.cornerRoundRadius}px`,
            //     padding: `${this.cardProps.verticalPadding}px ${this.cardProps.horizontalPadding}px`
            // }
        },
        stylesheet() {
            return styleSheet(
                {
                    themeKey: this.id || 'default-theme',
                    layoutType: this.type,
                    pageContainerId: ''
                }, generateJsStyles(this.type, this.allStyleProps))
        },
        currentElement() {
            return this.elements && this.elements.find(e => e && e.className === this.currentElementClass)
        },
        previewUrl() {
            // let options = {suggestionLimit: 7, carouselLimit: 1, rootElement: this.rootElement}
            return iframeUrl({
                stylesheet: this.stylesheet,
                rootElement: this.rootElement,
                galleryId: this.previewGallery || null,
                themeId: this.id,
                styleUrl: null
            })
        }
    },
    methods: {
        updateCurrentElement(val) {
            Object.assign(this.currentElement, val)
        },
        setTheme(theme) {
            let data = upgradeTheme(this.type, {
                ...theme.base,
                ...theme.layout,
                userStyles: theme.cardProps.userStyles,
                cardDynamicProps: theme.cardProps.userStyles || [],
                cardProps: theme.cardProps,
                elements: theme.elements || []
            });
            delete data.rootElement;
            Object.assign(this, data)
        },
        async loadTheme() {
            if (this.id) {
                let resp = await this.$api.get(`/galleries/themes/${this.id}`);
                let theme = resp.data;
                this.name = theme.name;
                this.setTheme(theme.styles);
                this.siteId = theme.site_id;
            }
        },
        async loadGalleryOptions() {

            if (this.siteId && this.id) {
                let resp = await this.$api.get(`/galleries/sites/${this.siteId}/galleries`);
                let allGalleries = resp.data;
                this.availableGalleries = allGalleries.filter(x =>
                    x.card_theme_id === this.id || x.carousel_theme_id === this.id
                );
            }
        },
        selectElement(el) {
            this.currentElementClass = el;
            this.$refs.tabs.goToTab('Element')
        },
        userPropsObj(props) {
            console.log('userPropsObj.before', props);
            if (isIterable(props)) {
                props = Array.from(props)
                props = props.map(x => Array.isArray(x) ? x : [x.name, x.value])
                props = props.reduce((r, [name, value]) => ({...r, [name]: value}), {});
                console.log('userPropsObj.after', props);
                return props;
            } else if (typeof props === 'object') {
                console.log('userPropsObj.after', props);
                return props;
            } else {
                console.log('userPropsObj.after', props);
                return {}
            }
        },
        async save() {
            await this.$api.put(`/galleries/themes/${this.id}/styles`, {
                data: this.allStyleProps,
                stylesheet: this.stylesheet
            })
        },
        async pushToStaging() {
            return await this.$api.put(`/galleries/themes/${this.id}/staging`)
        },
        async saveDraft() {
            this.startLoad();
            await this.save();
            await this.pushToStaging();
            this.finishLoad();
            toastr.success('Gallery saved successfully!');
        },
        async publish() {
            this.startLoad();
            await this.save();
            await this.pushToStaging();
            await this.$api.put(`/galleries/themes/${this.id}/production`);
            this.finishLoad();
            toastr.success('Gallery published successfully!');
        },
        async autoSave() {
            let newData = JSON.stringify(this.allStyleProps);
            if (this.savedData !== newData && !this.loading) {
                this.savedData = newData;
                await this.save();
                this.lastAutoSave = new Date();
            }
        },
        startLoad() {
            this.loading = true;
            window.Events.$emit('startPageLoad')
        },
        finishLoad() {
            this.loading = false;
            window.Events.$emit('finishPageLoad')
        },
        async scheduleAutoSave() {
            let self = this;
            setTimeout(async () => {
                if (self) {
                    await this.autoSave();
                    this.scheduleAutoSave();
                }
            }, 30000)
        },
        edit() {
            this.editingName = true;
            setTimeout(() => this.$refs.name.focus(), 10)
        },
        async saveName() {
            if (this.name) {
                this.editingName = false;
                await this.$api.put(`/galleries/themes/${this.id}/name`, {
                    name: this.name
                })
            }
        },
        cancel() {
            this.$router.push('/galleries')
        },
        calcFrameSize() {
            // let h = this.$refs.previewArea.clientHeight;
            let w = this.$refs.previewArea.clientWidth;
            this.frameSizePixels = w;
        }
    },
    async mounted() {
        this.startLoad()
        await this.loadTheme()
            .catch((e) => {
                console.error(e)
            })
            .then(() => {
                this.loadGalleryOptions()
            })
            .catch((e) => {
                console.error(e)
            })
            .then(() => this.finishLoad());
        this.calcFrameSize();
        this.scheduleAutoSave();
        this.resizeHandler = () => this.calcFrameSize();
        window.addEventListener('resize', this.resizeHandler)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler)
    }
}

</script>
