export const currentFilterVersion = 2;
export const focusableQuery = 'button, [href], input, select, summary, textarea, [tabindex]:not([tabindex="-1"])'

export const httpStatus = {
    ok: 200,
    noContent: 204,
    success: (status) => status >= 200 && status < 300,
    redirect: (status) => status >= 300 && status < 400,
    badRequest: 400,
    unauthorized: 401,
    paymentRequired: 402,
    forbidden: 403,
    notFound: 404,
    methodNotAllowed: 405,
    notAcceptable: 406,
    conflict: 409,
    error: (status) => status >= 400,
    clientError: (status) => status >= 400 && status < 500,
    internalServerError: 500,
    serverError: (status) => status >= 500,

};

export const powerups = ['amplify', 'sniply'];
export const knownTags = {
    // zest_content: {
    //     key: 'zest_content',
    //     iconClass: 'zest-icon',
    //     tagClass: 'zest-tag',
    //     label: 'Verified by Zest',
    //     filterLabel: 'Zest Only',
    //     showInFilters: true
    // },
    upcontent_featured: {
        key: 'upcontent_featured',
        tagClass: 'upcontent-tag',
        iconClass: 'fa fa-thumb-tack'
    }
};

export const sortTypes = (type, language, predictedPerformance) => [
    ...(type === 'topic' ? ['Relevance'] : []),
    'Recency',
    ...(!language || languagesSupportingShareability.includes(language) ? ['Shareability'] : []),
    'Influence',
    ...(type === 'collection' && predictedPerformance ? ['Added', 'Updated', 'Smart'] : []),
    ...(type === 'collection' && !predictedPerformance ? ['Added', 'Updated'] : []),
    ];

export const languagesSupportingShareability = ['en'];
export const languagesSupportingContentType = ['en'];

export const payFrequencies = [
    {
        value: "1m",
        description: "Monthly",
        upsell: "",
    },
    {
        value: "12m",
        description: "Annual",
        upsell: "(Save 10%)",
    },
];
export const defaultSortForCollection = 'Added';
export const disallowedSortsForTopic = ['Smart'];

export const disallowedSortsForCollection = ['Relevance'];

export const defaultSortForTopic = 'Recency';

export const chartColors = {
    $cornflower:    '#724EF5', // Cornflower Blue', alias Violet
    $pink: '#DB48FF',
    $periwinkle: '#CFDCFE',
    $minsk:         '#4F2C82', // UpContent Purple
    $dodge:         '#406ef6', // Dodgers Blue', alias Blue
    $saffron:       '#F4AF3D', // Saffron', Gold Accent', alias Gold
    $lightPurple:    '#8A70F6',
    $minskLight:         '#b380ff', // UpContent Purple
    $cornflowerLight:    '#ccbfff', // Cornflower Blue', alias Violet
    $ocean:         '#40AE82', // Ocean Green & Success', alias Green
    // $mojo:          '#C9454B', // Red Mojo', Danger Text', alias Red
    $clay:          '#1f2632', // Ebony Clay', alias Clay
}
